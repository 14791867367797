@import "~bootstrap/scss/bootstrap";
@import "~react-redux-toastr/lib/css/react-redux-toastr.min.css";

@font-face {
  font-family: "Inter";
  font-weight: 100;
  font-display: swap;
  src: url("../../assets/fonts/Inter/Inter-Thin.ttf") format("truetype");
}

@font-face {
  font-family: "Inter";
  font-weight: 200;
  font-display: swap;
  src: url("../../assets/fonts/Inter/Inter-ExtraLight.ttf") format("truetype");
}

@font-face {
  font-family: "Inter";
  font-weight: 300;
  font-display: swap;
  src: url("../../assets/fonts/Inter/Inter-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Inter";
  font-weight: 400;
  font-display: swap;
  src: url("../../assets/fonts/Inter/Inter-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Inter";
  font-weight: 500;
  font-display: swap;
  src: url("../../assets/fonts/Inter/Inter-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Inter";
  font-weight: 600;
  font-display: swap;
  src: url("../../assets/fonts/Inter/Inter-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "Inter";
  font-weight: 700;
  font-display: swap;
  src: url("../../assets/fonts/Inter/Inter-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Inter";
  font-weight: 800;
  font-display: swap;
  src: url("../../assets/fonts/Inter/Inter-ExtraBold.ttf") format("truetype");
}

@font-face {
  font-family: "Inter";
  font-weight: 900;
  font-display: swap;
  src: url("../../assets/fonts/Inter/Inter-Black.ttf") format("truetype");
}